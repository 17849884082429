import {createApp} from "vue";
import App from "@/App.vue";
import router from "@/router";
import {createI18n} from "vue-i18n";
import {translations} from "@/locale/Translations";

function getLang() {
	if (navigator.languages != undefined)
		return navigator.languages[0];
	return navigator.language;
}

export const helperFunctions = {
	getCountryParam: (): string => {
		const urlParams = new URLSearchParams(window.location.search);
		let country: string | null = "DE";
		if (urlParams.has("country")) {
			country = urlParams.get("country");
		}
		return <string>country;
	}
};

const i18n = createI18n({
	locale: helperFunctions.getCountryParam(),
	fallbackLocale: "DE",
	messages: translations
});

const app = createApp(App).use(router);
app.use(i18n);
app.mount("#app");
