<template>

	<template v-for="(item, index) of data">
		<div v-if="isData(item.key)" class="section-wrap" :class="item.index % 2 === 0 ? 'even' : 'odd'">
			<div class="section-container overview">
				<div class="section">
					<div class="section-title">
						<span class="section-icon" v-if="item.image" :class="item.image"></span>
						<p v-if="item.title" class="title">{{ item.title }}</p>
						<p v-if="item.description" class="description">{{ item.description }}</p>
					</div>
					<ItemList :data="getData(item.key)" :overview="true"/>
					<span v-if="sectionsMap[item.key]" class="toggle-detail" @click="toggleDetail(item.key)">
						<template v-if="sectionsMap[item.key].isVisible">
							{{ $t("expandShowLess") }}
						</template>
						<template v-else>
							{{ $t("expandShowMore") }}
						</template>
					</span>
				</div>
			</div>
			<div v-if="sectionsMap[item.key] && sectionsMap[item.key].isVisible"
				 class="section-container detail">
				<div class="section">
					<ItemList :data="getData(item.key)"/>
				</div>
			</div>
		</div>
	</template>

</template>

<script>
import ItemList from "@/components/ItemList";
export default ({
	name: "MainView",
	components: {
		ItemList
	},
	data() {
		return {
			sectionsMap: {}
		}
	},
	methods: {
		getData: function(itemName) {
			for (let dataItem in this.data) {
				if (this.data.hasOwnProperty(dataItem) && this.data[dataItem].key === itemName) {
					if(!this.sectionsMap.hasOwnProperty(this.data[dataItem].key)){
						this.sectionsMap[this.data[dataItem].key] = {}
						this.sectionsMap[this.data[dataItem].key].isVisible = false;
					}
					return this.data[dataItem];
				}
			}
		},
		isData: function(itemName) {
			return this.data && this.data.hasOwnProperty(itemName) && this.data[itemName].items && this.data[itemName].items.length > 0;
		},
		getClass: function(index) {
			return index % 2 === 0 ? 'even' : 'odd'
		},
		toggleDetail: function(key) {
			if (this.sectionsMap[key] && this.sectionsMap[key].hasOwnProperty("isVisible")) {
				this.sectionsMap[key].isVisible = !this.sectionsMap[key].isVisible;
			}
		}
	},
	props: ["data"],
	mounted() {
		this.sectionsMap = {}
	}
});
</script>
