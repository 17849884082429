export const translations = {
	US: {
		headerMainTitle: "Discover something new.",
		freeBtn: "Free",
		footer: {
			infoTitle: "We are here for you!",
			address: "retail7 GmbH \n Friedrichstraße 204 \n 10117 Berlin \n Germany",
			contactsTitle: "Contacts",
			contactMail: "info{'@'}retail7.io",
			contactPhone: "+49 (30) 80098931-0",
			supportTitle: "Support 24/7",
			supportMail: "support{'@'}retail7.io",
			supportPhone: "+49 (30) 80098931-0",
			factsTitle: "Facts",
			videoTutorials: "Video Tutorials",
			systemRequirements: "System Requirement",
			watchMore: "See also",
			beautySalons: "For Beauty Parlors",
			tattooStudios: "For Tattoo Parlors",
			piercingStudios: "For Piercing Parlors",
			hospitality: "For Hospitality",
			goodToKnowTitle: "Worth Knowing",
			macOs: "RETAIL7 for macOS",
			iOs: "RETAIL7 for iPadOS & iOS",
			android: "RETAIL7 for Android",
			windows: "RETAIL7 for Windows",
			linux: "RETAIL7 for Linux",
			resourcesTitle: "Resources",
			terms: "Terms and Conditions",
			imprint: "Imprint",
			privacyPolicy: "Privacy Policy",
			contact: "Contact",
			downloads: "Downloads",
			pressMaterial: "Press material",
			career: "Career",
			copyright: "© RETAIL7 2023 All rights reserved."
		},
		loginForm: {
			title: "Let's start now!",
			description: "To login, please enter your email and password below.",
			usernameFieldLabel: "Email",
			passwordFieldLabel: "Password",
			submitButtonText: "Login",
			cancelButtonText: "Cancel",
			emptyFieldError: "Empty field",
			formError: "Sorry, the credentials are wrong or user does not exist. Please try again."
		},
		contactSection: {
			title: "Contacts",
			name: "Name",
			company: "Company",
			copyright: "Copyright",
			postalAddress: "Address",
			dataPrivacyLink: "Privacy policy",
			email: "Email",
			phone: "Support - Hotline",
			termsOfUseLink: "Terms of use"
		},
		expandShowMore: "Show more",
		expandShowLess: "Show less",
		paymentAppType_PER_SYSTEM: "(Per system)",
		paymentAppType_PER_STORE: "(Per store)",
		paymentAppType_PER_DEVICE: "(Per device)",
		paymentTimeFrames_MONTHLY: "Monthly",
		paymentTimeFrames_QUARTERLY: "Quarterly",
		paymentTimeFrames_SEMI_ANNUAL: "Semi-annual",
		paymentTimeFrames_ANNUAL: "Annual",
		deviceTypesTitle: "The following device types will be charged",
		deviceType_POS: "POS",
		deviceType_CustomerDisplay: "Customer display",
		deviceType_InventoryApp: "InventoryApp",
		deviceType_Orderman: "Orderman",
		deviceType_WaiterCallSystem: "WaiterCallSystem",
		deviceType_DigitalSignage: "Digital signage",
		deviceType_ProductionDisplay: "Production display",
		backBtn: "Back"
	},
	DE: {
		headerMainTitle: "Entdecke etwas Neues.",
		freeBtn: "Kostenlos",
		footer: {
			infoTitle: "Wir sind für Dich da!",
			address: "retail7 GmbH \n Friedrichstraße 204 \n 10117 Berlin \n Deutschland",
			contactsTitle: "Kontakt",
			contactMail: "info{'@'}retail7.io",
			contactPhone: "+49 (30) 80098931-0",
			supportTitle: "Support 24/7",
			supportMail: "support{'@'}retail7.io",
			supportPhone: "+49 (30) 80098931-0",
			factsTitle: "Fakten",
			videoTutorials: "Video Tutorials",
			systemRequirements: "Systemanforderungen",
			watchMore: "Siehe auch",
			beautySalons: "Für Beautysalons",
			tattooStudios: "Für Tattoostudios",
			piercingStudios: "Für Piercingstudios",
			hospitality: "Für Gastronomen",
			goodToKnowTitle: "Gut zu Wissen",
			macOs: "RETAIL7 für macOS",
			iOs: "RETAIL7 für iPadOS & iOS",
			android: "RETAIL7 für Android",
			windows: "RETAIL7 für Windows",
			linux: "RETAIL7 für Linux",
			resourcesTitle: "Ressourcen",
			terms: "Allgemeine Geschäftsbedingungen",
			imprint: "Impressum",
			privacyPolicy: "Datenschutzerklärung",
			contact: "Kontakt",
			downloads: "Downloads",
			pressMaterial: "Pressematerial",
			career: "Karriere",
			recommendations:"Empfehlungsprogramm",
			copyright: "© RETAIL7 2023 Alle Rechte vorbehalten."
		},
		loginForm: {
			title: "Los gehts!",
			description: "Um Dich einzuloggen, gib bitte deine E-Mail und dein Passwort ein.",
			usernameFieldLabel: "E-Mail",
			passwordFieldLabel: "Passwort",
			submitButtonText: "Anmelden",
			cancelButtonText: "Abbrechen",
			emptyFieldError: "Bitte gib einen Wert ein",
			formError: "Dein Benutzername oder Passwort ist falsch oder der Benutzer existiert nicht. Bitte versuche es erneut."
		},
		contactSection: {
			title: "Kontakte",
			name: "Name",
			company: "Firma",
			copyright: "Copyright",
			postalAddress: "Adresse",
			dataPrivacyLink: "Datenschutzerklärung",
			email: "E-Mail",
			phone: "Support - Hotline",
			termsOfUseLink: "Nutzungsbedingungen"
		},
		expandShowMore: "Zeig mehr",
		expandShowLess: "Zeig weniger",
		paymentAppType_PER_SYSTEM: "(Pro System)",
		paymentAppType_PER_STORE: "(Pro Geschäft)",
		paymentAppType_PER_DEVICE: "(Pro Gerät)",
		paymentTimeFrames_MONTHLY: "Monatlich",
		paymentTimeFrames_QUARTERLY: "Vierteljährlich",
		paymentTimeFrames_SEMI_ANNUAL: "Halbjährlich",
		paymentTimeFrames_ANNUAL: "Jährlich",
		deviceTypesTitle: "Die folgenden Gerätetypen werden berechnet",
		deviceType_POS: "Kasse",
		deviceType_CustomerDisplay: "Kunden-Display",
		deviceType_InventoryApp: "Bestandsverwaltung",
		deviceType_Orderman: "Kellner-App",
		deviceType_WaiterCallSystem: "WaiterCallSystem",
		deviceType_DigitalSignage: "Digital signage",
		deviceType_ProductionDisplay: "Production Display",
		backBtn: "Zurück"
	}
};
