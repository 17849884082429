<template>
	<div class="image_slider_component" v-if="modelValue">
		<div class="gallery-container">
			<div class="image-container">
				<div class="slider" ref="slider" v-if="modelValue.images.length > 0">
					<div class="holder animate" ref="holder"
						 :style="{'width': holderWidth, 'max-height': holderHeight}">
						<div class="slide-wrapper" v-for="(image, index) in modelValue.images"
							 :style="{'width': imageWidth, 'max-height': imageHeight, 'margin-right': imageGapRight}">
							<div class="slide">
								<img class="image click" :src="image">
							</div>
						</div>
					</div>
				</div>
				<template v-if="modelValue.images.length > 1">
					<span class="font-icon-previous pointer" ref="foreground01"
						  :class="{'no-item': currentImageIndex === 0}"
						  @click="prevImageClick()"></span>

					<span class="font-icon-next pointer" ref="foreground02"
						  :class="{'no-item': currentImageIndex === modelValue.images.length - 1}"
						  @click="nextImageClick()"></span>
				</template>

			</div>

			<div v-if="modelValue.images.length > 1" class="dot-container">
				<span v-for="dotIndex in modelValue.images.length" class="dot pointer"
					  :class="{'dot-active': currentImageIndex === dotIndex - 1}"
					  @click="onImageChange(dotIndex)">
				</span>
			</div>
		</div>
	</div>
</template>

<script>

function moveElements(vueComponent) {
	if (!vueComponent.$refs.holder) {
		return;
	}
	const model = vueComponent.modelValue;

	if (model.imageWidth) {
		let width;
		const sliderWidth = vueComponent.$refs?.slider?.getBoundingClientRect().width || model.imageWidth;
		const imageWidth = model.gap ? model.imageWidth + Number(model.gap) : model.imageWidth;

		if (model.gap) {
			width = sliderWidth <= imageWidth ? sliderWidth + Number(model.gap) : imageWidth;
		} else {
			width = sliderWidth <= imageWidth ? sliderWidth : imageWidth;
		}
		vueComponent.$refs.holder.style.transform = "translate3d(-" + vueComponent.currentImageIndex * width + "px,0,0)";
	} else {
		vueComponent.$refs.holder.style.transform = "translate3d(-" + vueComponent.currentImageIndex * vueComponent.$refs.slider.offsetWidth + "px,0,0)";
	}
}

function getStyles() {
	getHolderStyles(this);
	getImageStyles(this);
	moveElements(this);
}

function getHolderStyles(vueComponent) {
	const model = vueComponent.modelValue;
	if (model && model.images && model.images.length > 0) {
		let width;
		if (model.imageWidth) {
			const sliderWidth = vueComponent.$refs?.slider?.getBoundingClientRect().width || model.imageWidth;
			const imageWidth = model.gap ? model.imageWidth + Number(model.gap) : model.imageWidth;

			if (model.gap) {
				width = sliderWidth <= imageWidth ? sliderWidth + Number(model.gap) : imageWidth;
			} else {
				width = sliderWidth <= imageWidth ? sliderWidth : imageWidth;
			}
		} else {
			width = model.images.length * 100 + "px";
		}
		if (model.imageHeight) {
			vueComponent.holderHeight = model.imageHeight + "px";
		}
		vueComponent.holderWidth = model.images.length * Math.ceil(width) + "px";
	}
}

function getImageStyles(vueComponent) {
	const model = vueComponent.modelValue;
	if (model && model.images && model.images.length > 0) {
		if (model.imageWidth) {
			const sliderWidth = vueComponent?.$refs?.slider?.getBoundingClientRect().width || model.imageWidth;
			const width = sliderWidth <= model.imageWidth ? sliderWidth : model.imageWidth;
			vueComponent.imageWidth = `${Math.floor(width)}px`;
		} else {
			vueComponent.imageWidth = `${100 / model.images.length}%`;
		}
		if (model.imageHeight) {
			vueComponent.imageHeight = `${model.imageHeight}px`;
		}
		if (model.gap) {
			vueComponent.imageGapRight = `${model.gap}px`;
		}
	}

}

export default ({
	name: "ImageSlider",
	props: ["modelValue"],
	data() {
		return {
			currentImageIndex: 0,
			holderWidth: "auto",
			holderHeight: "auto",
			imageWidth: "auto",
			imageHeight: "auto",
			imageGapRight: "auto"
		}
	},
	methods: {
		nextImageClick: function() {
			if (this.modelValue.images && this.currentImageIndex < this.modelValue.images.length - 1) {
				this.currentImageIndex++;
				moveElements(this);
			}
		},
		prevImageClick: function() {
			if (this.currentImageIndex > 0) {
				this.currentImageIndex--;
				moveElements(this);
			}
		},
		onImageChange: function(dotIndex) {
			this.currentImageIndex = dotIndex - 1;
			moveElements(this);
		}
	},
	mounted() {
		getHolderStyles(this);
		getImageStyles(this);
		window.addEventListener('resize', getStyles.bind(this));
	},
	unmounted() {
		window.removeEventListener('resize', getStyles.bind(this));
	}
});
</script>
