import {createRouter, createWebHashHistory, RouteRecordRaw} from "vue-router";
import MainView from "../views/MainView.vue";
import DetailView from "../views/DetailView.vue";

const routes: Array<RouteRecordRaw> = [
	{
		path: "/",
		name: "marketplace",
		component: MainView,
	},
	{
		path: "/:category/:id",
		name: "detail",
		component: DetailView,
		props: true
	}
];

const router = createRouter({
	history: createWebHashHistory(),
	routes,
});

export default router;
