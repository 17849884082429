<template>
	<div class="btn-component" v-if="modelValue">
		<template v-if="modelValue.type ==='ButtonWithSubmenu'">
			<div v-if="modelValue.options && modelValue.options[selectedOptionIndex]"
				 class="btn-width-submenu d-inline-block">
				<button role="button" class="btn btn-submenu" type="submit" @click="onClick($event)" ref="btnRef"
						:class="{'has-submenu': modelValue.options.length > 1, 'has-type': modelValue.options[selectedOptionIndex].type !== undefined}">
					<span class="btn-text">
						<div class="align-text">
							<span v-if="modelValue.options[selectedOptionIndex].type">{{
									modelValue.options[selectedOptionIndex].type
								}}</span>
							<span>{{ modelValue.options[selectedOptionIndex].value }}</span>
						</div>
					</span>
					<span @click="subMenuToggle($event)" ref="submenuToggleBtn" class="submenu-btn font-icon"
						  v-if="modelValue.options.length > 1"></span>
				</button>
				<div v-if="modelValue.options.length > 1 && isSubmenuOpen" ref="submenu"
					 class="submenu-wrap">
					<ul class="submenu">
						<li @click="subMenuItemClick(index)"
							v-for="(option, index) in modelValue.options"
							class="submenu-entry"
							:class="{'selected': selectedOptionIndex === index, 'last': index === modelValue.options.length - 1 }">
							<span>{{ modelValue.options[index] }}</span>
						</li>
					</ul>
				</div>
			</div>
		</template>
		<template v-else-if="modelValue.type ==='Button'">
			<div class="btn" @click="onClick($event)" ref="btnRef">{{ modelValue.text }}</div>
		</template>
		<template v-else-if="modelValue.type ==='ButtonPrimary'">
			<div class="btn btn-primary" @click="onClick($event)" ref="btnRef">{{ modelValue.text }}</div>
		</template>
		<template v-else-if="modelValue.type ==='ButtonSecondary'">
			<div class="btn btn-secondary" @click="onClick($event)" ref="btnRef">{{ modelValue.text }}</div>
		</template>

	</div>
</template>

<script>
export default ({
	name: "ButtonComponent",
	props: ["modelValue"],
	components: {},
	data() {
		return {
			isSubmenuOpen: false,
			selectedOptionIndex: 0
		}
	},
	methods: {
		subMenuListener: function(vueComponent) {
			return (event) => {
				if (vueComponent.isSubmenuOpen) {
					const element = vueComponent.$refs["btnRef"];
					if (!event.composedPath().includes(element)) {
						vueComponent.isSubmenuOpen = false;
					}
				}
			};
		},
		onClick: function(event) {
			this.modelValue.clicked(event, this.selectedOptionIndex)
		},
		subMenuToggle: function(event) {
			event.stopPropagation();
			this.isSubmenuOpen = !this.isSubmenuOpen;
			this.$nextTick(() => {
				const menuElement = this.$refs.submenu;
				const contentElement = document.getElementById("app");
				if (menuElement) {
					const menuPosX = menuElement.getBoundingClientRect().x;
					const contentPosX = contentElement.getBoundingClientRect().x;
					if (contentPosX > menuPosX) {
						menuElement.classList.add("align-left");
					}
					if (menuPosX < 0) {
						menuElement.classList.add("align-left");
					}
				}
			});
		},
		subMenuItemClick: function(index) {
			this.selectedOptionIndex = index;
			this.isSubmenuOpen = false;
		}
	},
	mounted() {
		if (this.modelValue && this.modelValue.type === "ButtonWithSubmenu") {
			if (this?.$refs["submenuToggleBtn"]) {
				const subMenuListener = this.subMenuListener(this);
				window.addEventListener("click", subMenuListener);
				this.subMenuButtonFunction = subMenuListener
			}
		}
	},
	beforeUnmount() {
		if (this.modelValue && this.modelValue.type === "ButtonWithSubmenu") {
			window.removeEventListener("click", this.subMenuButtonFunction);
		}
	}
});

</script>

