<template>
	<div class="modal-wrap" v-if="modelValue && modelValue.isVisible"
		 :class="{'error': this.formError && usernameField.error && passwordField.error}">
		<div class="modal">
			<p class="title">{{ $t("loginForm.title") }}</p>
			<p class="description">{{ $t("loginForm.description") }}</p>
			<InputField v-model="usernameField"/>
			<InputField v-model="passwordField"/>
			<div class="form-error" v-if="this.formError && usernameField.error && passwordField.error">
				{{ $t("loginForm.formError") }}
			</div>
			<div class="btn-wrap">
				<ButtonComponent v-model="cancelBtnModel"></ButtonComponent>
				<ButtonComponent v-model="submitBtnModel"></ButtonComponent>
			</div>

		</div>
	</div>
</template>

<script>

import axios from "axios";
import ButtonComponent from "@/components/ButtonComponent";
import InputField from "@/components/InputField";
import sha256 from 'crypto-js/sha256';

export default ({
	name: "LoginModal",
	props: ["modelValue"],
	components: {
		ButtonComponent,
		InputField
	},
	data() {
		return {
			submitBtnModel: undefined,
			cancelBtnModel: undefined,
			usernameField: undefined,
			passwordField: undefined,
			formError: false
		}
	},
	methods: {},
	async created() {
		this.submitBtnModel = {
			text: this.$i18n.t("loginForm.submitButtonText"),
			clicked: async () => {
				this.formError = false;
				const pluginId = this.modelValue.pluginId;
				const httpRequest = async (hostStr) => {
					return new Promise((resolve, reject) => {
						let req = new XMLHttpRequest();
						req.onload = function reqListener() {
							return resolve(req.responseText);
						};

						req.onerror = function reqError(err) {
							console.log("Request Error:", err);
							return reject(err);
						};
						req.open("GET", hostStr, true);
						req.setRequestHeader("Cache-Control", "no-cache");

						req.send();
					});
				}

				const host = window.location.hostname;
				const password = this.passwordField.value;
				const email = this.usernameField.value;

				if (!password || !email) {
					this.passwordField.error = !password;
					this.usernameField.error = !email;
					return
				}

				let lookupPath;
				if (host === "localhost") {
					lookupPath = "https://lookup.dev-retail7.com";
				} else {
					lookupPath = "https://" + host.replace("marketplace", "lookup");
				}

				let salt;
				let serverToUse;
				try {
					salt = await httpRequest(lookupPath + "/salt");
					if (salt) {
						serverToUse = lookupPath;
					}
				} catch (e) {
					console.warn("Connection to lookup '" + lookupPath + "' failed, trying next", e);
				}

				if (!serverToUse) {
					console.warn("Cannot establish connection to lookup servers " + lookupPath);
				}

				const secret = sha256(password).toString();

				const hash = sha256(`${email}${salt}${secret}`);

				let lookupResult;

				try {
					lookupResult = JSON.parse(await httpRequest(serverToUse + "/lookup/" + hash));
				} catch (err) {
					this.formError = true;
					this.passwordField.error = true;
					this.usernameField.error = true;
					console.warn(err)
				}

				if (lookupResult && lookupResult.config) {
					const data = JSON.stringify({
						provider: "UserAuthenticationProvider",
						credentials: {
							email, secret, remoteLogin: true
						}
					})

					await axios.post(
						lookupResult.config + "/api/auth/token/issue",
						data,
						{
							headers: {
								["Content-Type"]: "application/json"

							},
							withCredentials: true
						})
						.then(function(response) {
							if (response && response.status === 200) {
								window.location.href = `${lookupResult.config}?target=marketplace&id=${pluginId}`;
							}
						})
						.catch(function(error) {
							console.warn(error);
						});
				}

			},
			type: "ButtonPrimary"
		}
		this.cancelBtnModel = {
			text: this.$i18n.t("loginForm.cancelButtonText"),
			clicked: () => {
				this.modelValue.isVisible = false;
				this.passwordField.value = "";
				this.usernameField.value = "";
				this.passwordField.error = false;
				this.usernameField.error = false;
				this.formError = false;
			},
			type: "ButtonSecondary"
		}
		this.usernameField = {
			type: "text",
			label: this.$i18n.t("loginForm.usernameFieldLabel"),
			errorText: this.$i18n.t("loginForm.emptyFieldError")
		}
		this.passwordField = {
			type: "password",
			label: this.$i18n.t("loginForm.passwordFieldLabel"),
			errorText: this.$i18n.t("loginForm.emptyFieldError")
		}
	}
});
</script>
