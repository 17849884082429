<template>
	<div class="item-list" ref="list">
		<template v-for="(item, index) of data.items">
			<div v-if="!overview || (overview === true && index <= itemsCount)" class="item">
				<div class="image-wrap" @click="goToDetail(item, data.alias)" :class="getImage(item)"
					 :style="getElmStyles(item)">
					<span v-if="item.update" class="label update">UPDATE</span>
					<span v-if="item.new" class="label new">new!</span>
				</div>
				<div v-if="!overview" class="item-summary">
					<p class="ttl" @click="goToDetail(item, data.alias)">{{ item.name }}</p>
					<div class="description">{{ item.shortDescription }}</div>

					<template
						v-if="item.unitPrice && item.unitPrice.deviceTypes && item.unitPrice.deviceTypes.length > 0">
						<p class="device-types-title">{{ $t("deviceTypesTitle") }}</p>
						<ul class="device-types-list">
							<li v-for="deviceType in item.unitPrice.deviceTypes">
								{{ $t("deviceType_" + deviceType) }}
							</li>
						</ul>
					</template>

					<ButtonComponent v-model="btnModelsMap[item.id]"></ButtonComponent>
				</div>
			</div>
		</template>

	</div>
	<LoginModal v-model="loginModalModel"/>
</template>

<script>
import DetailView from "@/views/DetailView";
import ButtonComponent from "@/components/ButtonComponent";
import LoginModal from "@/components/LoginModal";

function getBtnsMap(component) {
	component.btnModelsMap = {};
	for (let item of component.data.items) {
		let type;
		if (item.unitPrice.paymentType === "RECURRING_PAYMENT") {
			const recurringPaymentFrame = item.unitPrice.recurringPaymentFrame ? component.$i18n.t("paymentTimeFrames_" + item.unitPrice.recurringPaymentFrame) : "";
			const paymentApplication = item.unitPrice.paymentApplication ? component.$i18n.t("paymentAppType_" + item.unitPrice.paymentApplication) : "";
			type = `${recurringPaymentFrame} ${paymentApplication}`;
		}
		component.btnModelsMap[item.id] = {
			options: [
				{
					type,
					value: item.unitPrice.value === 0 ? component.$i18n.t("freeBtn") : item.unitPrice.formatted
				}
			],
			clicked: () => {
				component.loginModalModel.isVisible = true;
				component.loginModalModel.pluginId = item.id;
			},
			type: "ButtonWithSubmenu"
		}
	}
}

export default ({
	name: "ItemList",
	props: ["data", "overview"],
	components: {
		DetailView,
		ButtonComponent,
		LoginModal
	},
	data() {
		return {
			btnModelsMap: {},
			loginModalModel: {
				isVisible: false
			},
			itemsCount: 4
		}
	},
	watch: {
		data() {
			getBtnsMap(this);
		}
	},
	methods: {
		getElmStyles: function(elm) {
			let styles = "";
			if (elm && elm.images && typeof elm.images === "string" && elm.images !== "" && elm.images.trim().length !== 0 && elm.images.trim() !== ".") {
				const imagesPathArray = elm.images.split(",")
				if (imagesPathArray && imagesPathArray.length > 0) {
					styles += "background-image: url(" + imagesPathArray[0] + ")";
				}

			}
			return styles;
		},
		getTranslation: function(elm) {
			return elm?.i18nVariants?.en_US;
		},
		goToDetail: function(elm, category) {
			this.$router.push({name: "detail", params: {id: elm.id, category: category, detailName: elm.name}});
		},
		getImage: function(elm) {
			if (
				!elm.images ||
				elm.images.length === 0 ||
				(
					elm.images &&
					typeof elm.images === "string" &&
					(
						elm.images.trim().length === 0 ||
						elm.images === "."
					)
				)
			) {
				return "no-image";
			}
			return "";
		},
		countLineItems: function() {
			if (this && this.$refs && this.$refs.list && this.$refs.list.clientWidth) {
				const listWidth = this.$refs.list.clientWidth;
				const itemWidth = 175;
				this.itemsCount = Math.floor(listWidth / itemWidth) >= 3 ? Math.floor(listWidth / itemWidth) - 1 : 3;
			}
		}
	},
	mounted() {
		getBtnsMap(this);
		if (this.overview) {
			this.countLineItems();
			window.addEventListener("resize", this.countLineItems);
		}
	},
	beforeUnmount() {
		window.removeEventListener("resize", this.countLineItems);
	}
});
</script>

