<template>
	<div class="input-field"
		 :class="{'focus': isFocused, 'move-up': isFocused || ( modelValue.value && modelValue.value !== ''), 'error': modelValue.error}">
		<div class="label">{{ modelValue.label }}</div>
		<input
			:type="modelValue.type"
			@input="updateValue($event.target.value)"
			@focus="focusHandler"
			@blur="focusHandler"
			:value="modelValue.value"
		>
		<div class="error-label" v-if="modelValue.error && modelValue.errorText">
			{{ modelValue.errorText }}
		</div>
		<span class="clear-field" @click="clearField"></span>
	</div>
</template>

<script>

export default ({
	name: "InputField",
	props: ["modelValue"],
	data() {
		return {
			isFocused: false
		}
	},
	methods: {
		focusHandler: function() {
			this.isFocused = !this.isFocused;
			this.modelValue.error = false;
		},
		updateValue: function(inputValue) {
			this.modelValue.value = inputValue;
		},
		clearField: function() {
			this.modelValue.value = "";
		}
	},
	async created() {
	}
});
</script>
