<template>
	<div v-if="detailData">
		<div class="detail-head">
			<div class="detail-head-image" :class="getImage(detailData)"
				 :style="getElmStyles(detailData)"></div>
			<div class="detail-head-content">
				<div class="title">{{ detailData.name }}</div>
				<div class="description">{{ detailData.shortDescription }}</div>
				<template v-if="detailData.unitPrice && detailData.unitPrice.deviceTypes && detailData.unitPrice.deviceTypes.length > 0">
					<p class="device-types-title">{{ $t("deviceTypesTitle") }}</p>
					<ul class="device-types-list">
						<li v-for="deviceType in detailData.unitPrice.deviceTypes">
							{{ $t("deviceType_" + deviceType) }}
						</li>
					</ul>
				</template>
				<div>
					<div class="label-installed" v-if="detailData.installed"><span>Installed</span></div>
					<template v-else>
						<ButtonComponent v-model="btnModel"></ButtonComponent>
					</template>
				</div>
			</div>

		</div>
		<template v-if="detailData.images && detailData.images.length > 0">
			<div class="detail-image-wrap">
				<ImageSlider v-model="galleryModel"></ImageSlider>
			</div>
		</template>
		<div class="detail-section">
			<div class="expandable-wrap" ref="expandableWrap">
				<div class="expandable" ref="expandable">
					{{ detailData.description }}
				</div>
			</div>
			<div class="expand-link-wrap" v-if="expandableShown">
				<a @click="onExpandClick()" class="expand-link" :class="{'expanded': expanded}">
					<template v-if="expanded">
						{{ $t("expandShowLess") }}
					</template>
					<template v-else>
						{{ $t("expandShowMore") }}
					</template>
				</a>
			</div>
		</div>

		<div class="detail-summary-wrap"
			 v-if="detailData.contactInfo && Object.keys(detailData.contactInfo).length > 0">
			<p class="section-ttl">{{ $t("contactSection.title") }}</p>
			<div class="detail-summary">
				<template v-for="(value, key) in detailData.contactInfo">
					<div class="detail-summary-item" v-if="key !== 'name'">
						<div class="summary-ttl">{{ $t(`contactSection.${key}`) }}</div>
						<div class="summary-text">
							<template v-if="isLink(key)">
								<a class="link" :href="getLink(key, value)">{{ value }}</a>
							</template>
							<template v-else>
								{{ value }}
							</template>
						</div>
					</div>
				</template>
			</div>
		</div>

	</div>
	<LoginModal v-model="loginModalModel"/>

	<!--	<div class="detail-section" v-for="section in value.data.sections">-->
	<!--		<p class="section-ttl">Pricing</p>-->
	<!--		<div class="section-text">Donec dapibus mauris id odio ornare tempus. Duis sit amet accumsan-->
	<!--			justo, quis tempor ligula. Quisque quis pharetra felis. Ut quis consequat orci, at consequat-->
	<!--			felis.-->
	<!--		</div>-->
	<!--	</div>-->

</template>

<script>

import axios from "axios";
import ButtonComponent from "@/components/ButtonComponent";
import LoginModal from "@/components/LoginModal";
import ImageSlider from "@/components/ImageSlider";
import {helperFunctions} from "@/main";

function onResize() {
	this.$nextTick(() => {
		const expandableArea = this.$refs?.expandableWrap;
		const expandableContent = this.$refs?.expandable;
		const maxHeight = 24 * 4;
		if (expandableContent && expandableContent.offsetHeight > maxHeight) {
			this.expandableShown = true;
			expandableArea.style.maxHeight = this.expanded ? "initial" : maxHeight + "px";
		} else {
			this.expandableShown = false;
		}
	})
}

export default ({
	name: "DetailView",
	components: {
		ButtonComponent,
		LoginModal,
		ImageSlider
	},
	data() {
		return {
			detailData: undefined,
			btnModel: undefined,
			loginModalModel: {
				isVisible: false
			},
			imagesPathArray: [],
			expandableShown: false,
			expanded: false
		}
	},
	methods: {
		getElmStyles: function(elm) {
			let styles = "";
			if (this.imagesPathArray && this.imagesPathArray.length > 0) {
				styles += "background-image: url(" + this.imagesPathArray[0] + ")";
			}
			return styles;
		},
		getTranslation: function(elm) {
			return elm?.i18nVariants?.en_US;
		},
		isLink: function(key) {
			return key === "dataPrivacyLink" || key === "email" || key === "phone" || key === "termsOfUseLink";
		},
		getLink: function(key, value) {
			if (key === "email") {
				return "mailto:" + value
			}
			if (key === "phone") {
				return "tel:" + value
			}
			return value
		},
		onExpandClick: function() {
			this.expanded = !this.expanded;
			const expandableArea = this.$refs?.expandableWrap;
			const maxHeight = 24 * 4;
			if (expandableArea) {
				this.expandableShown = true;
				expandableArea.style.maxHeight = this.expanded === true ? "initial" : maxHeight + "px"
			}
		},
		getImage: function(elm) {
			if (
				!elm.images ||
				elm.images.length === 0 ||
				(
					elm.images &&
					typeof elm.images === "string" &&
					(
						elm.images.trim().length === 0 ||
						elm.images === "."
					)
				)
			) {
				return "no-image";
			}
			return "";
		}
	},
	async created() {
		const country = helperFunctions.getCountryParam();
		const host = window.location.host;
		const domain = window.location.hostname === "localhost" ? "marketplace-admin.dev-retail7.com" : host.replace("marketplace", "marketplace-admin");
		const response = await axios.get(`https://${domain}/api/v1/plugin-packages/${this.$route.params.id}?country=${country}`);
		this.detailData = response.data;
		this.detailData.id = `${this.detailData.packageId.company}:${this.detailData.packageId.group}:${this.detailData.packageId.name}`;
		if (!this.$route.params.detailName) {
			this.$router.push({name: "detail", params: {detailName: this.detailData.name}});
		}
		let type;
		if (this.detailData.unitPrice.paymentType === "RECURRING_PAYMENT") {
			const recurringPaymentFrame = this.detailData.unitPrice.recurringPaymentFrame ? this.$i18n.t("paymentTimeFrames_" + this.detailData.unitPrice.recurringPaymentFrame) : "";
			const paymentApplication = this.detailData.unitPrice.paymentApplication ? this.$i18n.t("paymentAppType_" + this.detailData.unitPrice.paymentApplication) : "";
			type = `${recurringPaymentFrame} ${paymentApplication}`;
		}
		this.btnModel = {
			options: [
				{
					type,
					value: this.detailData.unitPrice.value === 0 ? this.$i18n.t("freeBtn") : this.detailData.unitPrice.formatted
				}
			],
			clicked: () => {
				this.loginModalModel.isVisible = true
				this.loginModalModel.pluginId = this.detailData.id;
			},
			type: "ButtonWithSubmenu"
		}

		if (this.detailData && this.detailData.images && typeof this.detailData.images === "string" && this.detailData.images !== "" && this.detailData.images.trim().length !== 0 && this.detailData.images.trim() !== ".") {
			this.imagesPathArray = this.detailData.images.split(",");
			if (this.imagesPathArray && this.imagesPathArray.length > 1) {
				this.galleryModel = {
					images: this.imagesPathArray.splice(1, this.imagesPathArray.length),
					gap: 20,
					imageWidth: 560,
					imageHeight: 367
				}
			}

		}
		await this.$nextTick(() => {
			const expandableArea = this.$refs?.expandableWrap;
			const maxHeight = 24 * 4;
			if (expandableArea && expandableArea.offsetHeight > maxHeight) {
				this.expandableShown = true;
				expandableArea.style.maxHeight = maxHeight + "px"
			}
		})
	},
	mounted() {
		window.addEventListener('resize', onResize.bind(this));
	},
	unmounted() {
		window.removeEventListener('resize', onResize.bind(this));
	}
});
</script>
