const target = "https://retail7.io";
export const footerLinks = {
	US: {
		contactPhone: "tel:+4930800989310",
		supportPhone: "tel:+4930800989310",
		contactMail: "mailto:info@retail7.io",
		supportMail: "mailto:support@retail7.io",
		videoTutorials: "https://www.youtube.com/channel/UCGSmdEQddffseAbGihTIE_Q?disable_polymer=true",
		systemRequirements: target + "/us/en/system-requirement",
		beautySalons: target + "/us/en/pos-beauty-parlor",
		tattooStudios: target + "/us/en/pos-tattoo-parlors",
		piercingStudios: target + "/us/en/pos-piercing-parlors",
		hospitality: target + "/us/en/pos-gastronomy",
		macOs: target + "/us/en/macos-pos",
		iOs: target + "/us/en/ipad-pos",
		android: target + "/us/en/android-pos",
		windows: target + "/us/en/windows-pos",
		linux: target + "/us/en/linux-pos",
		terms: target + "/us/en/gtc",
		imprint: target + "/us/en/imprint",
		privacyPolicy: target + "/us/en/privacy-policy",
		contact: target + "/us/en/contact",
		downloads: target + "/us/en/downloads",
		pressMaterial: target + "/us/en/press-material",
		career: target + "/us/en/career"
	},
	DE: {
		contactPhone: "tel:+4930800989310",
		supportPhone: "tel:+4930800989310",
		contactMail: "mailto:info@retail7.io",
		supportMail: "mailto:support@retail7.io",
		videoTutorials: "https://www.youtube.com/channel/UCGSmdEQddffseAbGihTIE_Q?disable_polymer=true",
		systemRequirements: target + "/de/de/systemanforderungen",
		beautySalons: target + "/de/de/kassensystem-beautysalons",
		tattooStudios: target + "/de/de/kassensystem-tattostudios",
		piercingStudios: target + "/de/de/kassensystem-piercingstudios",
		hospitality: target + "/de/de/kassensystem-gastronomie",
		macOs: target + "/de/de/macos-kassensystem",
		iOs: target + "/de/de/ipad-kassensystem",
		android: target + "/de/de/android-kassensystem",
		windows: target + "/de/de/windows-kassensystem",
		linux: target + "/de/de/retail7-fuer-linux",
		terms: target + "/de/de/agb",
		imprint: target + "/de/de/impressum",
		privacyPolicy: target + "/de/de/datenschutzerklaerung",
		contact: target + "/de/de/kontakt",
		downloads: target + "/de/de/downloads",
		pressMaterial: target + "/de/de/pressematerial",
		career: target + "/de/de/karriere",
		recommendations: target + "/de/de/empfehlungsprogramm"
	}
};

export const footerStructure = {
	US: [
		{
			title: "infoTitle",
			entries: [
				{
					entries: [
						"address"
					]

				},
				{
					title: "contactsTitle",
					entries: ["contactMail", "contactPhone"]
				},
				{
					title: "supportTitle",
					entries: ["supportMail", "supportPhone"]
				}
			]
		},
		{
			title: "factsTitle",
			entries: [
				{
					entries: ["videoTutorials", "systemRequirements"]
				},
				{
					title: "watchMore",
					entries: ["beautySalons", "tattooStudios", "piercingStudios", "hospitality"]
				}
			]
		},
		{
			title: "goodToKnowTitle",
			entries: [
				{
					entries: [
						"macOs",
						"iOs",
						"android",
						"windows",
						"linux"
					]
				}
			]
		},
		{
			title: "resourcesTitle",
			entries: [
				{
					entries: [
						"terms",
						"imprint",
						"privacyPolicy",
						"contact",
						"downloads",
						"pressMaterial",
						"career"
					]
				}
			]
		}
	],
	DE: [
		{
			title: "infoTitle",
			entries: [
				{
					entries: [
						"address"
					]

				},
				{
					title: "contactsTitle",
					entries: ["contactMail", "contactPhone"]
				},
				{
					title: "supportTitle",
					entries: ["supportMail", "supportPhone"]
				}
			]
		},
		{
			title: "factsTitle",
			entries: [
				{
					entries: ["videoTutorials", "systemRequirements"]
				},
				{
					title: "watchMore",
					entries: ["beautySalons", "tattooStudios", "piercingStudios", "hospitality"]
				}
			]
		},
		{
			title: "goodToKnowTitle",
			entries: [
				{
					entries: [
						"macOs",
						"iOs",
						"android",
						"windows",
						"linux"
					]
				}
			]
		},
		{
			title: "resourcesTitle",
			entries: [
				{
					entries: [
						"terms",
						"imprint",
						"privacyPolicy",
						"contact",
						"downloads",
						"pressMaterial",
						"career",
						"recommendations"
					]
				}
			]
		}
	]
};